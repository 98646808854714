@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* @keyframes wave {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.animate-wave {
  animation: wave 1s ease-in-out infinite;
} */

/* @keyframes fadeInOutWave {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  25%, 75% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-10px);
  }
}

.animate-fade-in-out-wave {
  animation: fadeInOutWave 2s ease-in-out infinite;
} */

@keyframes fadeInOutWaveColors {
  60%, 80% {
    opacity: 1;
    transform: translateY(-10px); /* Adjust for initial and final state */
    color: white; /* Initial color */
  }
  
  30%,60% { /* Transition to middle color */
    opacity: 1;
    transform: translateY(0);
    color: black; /* Middle color */
  }
  60%, 80% { /* Duration for "Ash" color to be visible */
    opacity: 1;
    transform: translateY(0);
    color: gray; /* "Ash" color remains visible longer */
  }
}



.animate-fade-in-out-wave-colors {
  animation: fadeInOutWaveColors 10s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Apply the animation to the image */
.image-animation {
  animation: fadeIn 1s ease-in-out;
}

.wave-background {
  position: relative;
  width: 100%;
  height: 1200px; /* Adjust height as needed */
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, red 20%, #ffffff 80%);
  animation: wave-animation 10s infinite linear;
  z-index: -9;
}

@keyframes wave-animation {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* @keyframes wipe {
  0% {
    clip-path: polygon(0 0, 0 100%, 0 100%, 0 0);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.wipe-transition {
  animation: wipe 1s ease-in-out;
} */

/* @keyframes wipe {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

.wipe-transition {
  animation: wipe 1s ease-in-out;
} */

@keyframes wipe {
  0% {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wipe-transition {
  animation: wipe 1s ease-in-out, fade 1s ease-in-out;
}

.background-div1 {
  background-image: url('./Assets/Images/VideoDetailsBG.svg');
}
.background-div2 {
  background-image: url('./Assets/Images/VideoDetailsBG2.svg');
  height: 400px;
}
/* .background3 { */
  /* background-image: url('./Assets/Images/VideoDetailsBG3.svg'); */
  /* height: 500px;
  margin-top: -200px;
} */

.background4 {
  background-image: url('./Assets/Images/VideoDetailsBG4.svg');
  height: 500px;
}

@keyframes fadeFromBottom {
  0% {
    background-color: rgba(255, 255, 255, 0); /* Transparent */
    height: 0; /* Start with height 0 */
  }
  100% {
    background-color: rgba(255, 0, 0, 1); /* Your desired color */
    height: 200px; /* Final height of the div */
  }
}

.transition-div {
  width: 200px; /* Set the width of the div */
  height: 0; /* Start with height 0 */
  background-color: rgba(255, 255, 255, 0); /* Transparent */
  transition: background-color 0.5s ease, height 0.5s ease; /* Transition for background color and height */
}

.animation-container {
  position: relative;
  overflow: hidden;
}

.animation-container p {
  opacity: 0;
  position: relative;
}

.animate {
  animation: slideUpFadeInOut 5s linear forwards;
}

@keyframes slideUpFadeInOut {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  10%, 90% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Ensure paragraphs stay at top after animation */
  }
}

@font-face {
  font-family: raleway;
  src: url(../public/Raleway-VariableFont_wght.ttf)
}
.raleway{
  font-family: raleway;
}

.input-wrapper {
  width: fit-content; /* Adjust based on your requirement */
  background: linear-gradient(to right, white 80%, rgb(245, 120, 120) 10%);
  border-radius: 999px; /* To match your input's rounded corners */
  padding: 2px; /* Adjust padding to match your design */
}

.half-bg-input {
  background-color: transparent; 
  color: black; /* Adjust text color as needed */
  border: none;
  outline: none;
  width: 100%;
  padding: 10px 20px; /* Adjust padding to match your design */
  border-radius: 999px; /* To match your wrapper's rounded corners */
}

.half-bg-input:focus {
  border-color: gray; /* Adjust focus border color */
}







.animated-div {
  opacity: 0; /* Initially hide the divs */
}

.animate-left-to-right {
  animation: slideFromLeft 1s ease-in-out forwards;
}

@keyframes slideFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in-bottom {
  animation: fade-in-bottom 1s ease forwards;
}

.centered-line {
  width: 35%; /* Adjust the width as needed */
  margin: 0 auto; /* This centers the line horizontally */
}



.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.image-container {
  position: relative;
}

.youtube-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  .youtube-icon {
    top: 50%;
    left: 25%
  }
}

@media screen and (min-width: 640px) and (max-width: 769px) {
  .youtube-icon {
    top: 50%;
    left: 50%
  }
}

.youtube-icon2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  .youtube-icon2 {
    top: 50%;
    left: 60%
  }
}

.youtube-icon3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 768px) {
  .youtube-icon3 {
    top: 50%;
    left: 70%
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.BoxShadox {
  box-shadow: 6px 6px 5px rgba(0, 0, 0, 0.5);
}

.BoxShadowForAllVideo {
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
}

.animated-element {
  opacity: 0; /* Initial state: Hidden */
  transition: opacity 0.5s ease; /* Smooth transition */
}

.image-container {
  filter: brightness(0) invert(1) sepia(1) saturate(5) hue-rotate(180deg);
}

.image-invert {
  width: 100%; /* Set the desired width */
  height: auto; /* Let the height adjust proportionally */
}